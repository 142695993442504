import { Component, OnInit } from '@angular/core';
import { TopService } from '../../services/top.service';
import { OffersPopupComponent } from './offers-popup/offers-popup.component';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-offers-banner',
    templateUrl: './offers-banner.component.html',
    styleUrls: ['./offers-banner.component.scss'],
    standalone: true,
    imports: [NgIf, OffersPopupComponent]
})
export class OffersBannerComponent implements OnInit {
  isFaqIsOpen = false;

  constructor(
    public topService: TopService
    ) { }

  ngOnInit(): void {
  }

}
