import {IItem} from '../../buyer/interfaces/IItem';
import { FullOfferStatusEnum } from '../enums/offer-status';
import { OfferEventTypes } from '../enums/offer-types';


export interface LiveOfferPayload extends LiveOfferFilter {
  pagination: {
    pageSize: number;
    pageNumber: number;
  };
}

export interface LiveOfferResponse {
  pagination: {
    currentPage: number;
    pageSize: number;
    totalCount: number;
    totalPages: number;
  };
  values: LiveOffer[];
  counters: LiveOfferCounters;
}

export interface LiveOfferByIdResponse extends LiveOffer{
  counters: LiveOfferCounters;
}

export interface LiveOfferFilter {
  sortBySKU?: boolean,
  sortingCriteria?: number,
  searchQuery?: string,
  filter?: LiveOfferStateEnum
}

export interface LiveOffer {
  id: string;
  item: IItem;
  offer: {
    buyerSection: BuyerSellerSection;
    sellerSection: BuyerSellerSection;
    isSubscribed: boolean;
    customerId: string;
    status: FullOfferStatusEnum;
    expiresOn: string;
    updatedOn: string;
    cancellationReason: number;
    nextToReact: NextToReactEnum;
    subscribedUsers: string[];
    version: number,

    // UI properties
    expired?: boolean;
  };
}

export interface EventInfo {
  eventId: string;
  userId: string;
  eventType: number;
  offerPrice: number;
  effectivePrice: number;
  quantity: number;
  isFinal: boolean;
}

export interface BuyerSellerSection {
  eventId: string;
  eventType: number;
  status: SectionStatusEnum;
  userId: string;
  metadata: {
    offerPrice: number;
    effectivePrice: number;
    quantity: number;
    isFinal: boolean;
  };
}

export interface OfferOwnershipPayload {
  id: string,
  instanceId: string,
  version: number,
  effectivePrice: number
}

export enum LiveOfferStateEnum {
  All = 'all',
  Live = 'live',
  Counteroffers = 'counteroffers',
  Accepted = 'accepted',
  Canceled = 'canceled',
  Expired = 'expired'
}

export interface LiveOfferCounters {
  all: number;
  live: number;
  counteroffers: number;
  accepted: number;
  canceled: number;
  expired: number;
}
export enum NextToReactEnum {
  none,
  buyer,
  seller
}

export enum SectionStatusEnum {
  pending,
  visible,
  accepted,
  canceled,
  expired
}

export enum SortingCriteriaEnum {
  RecentlyAdded = 'Recently Added',
  Oldest = 'Oldest',
  HighestTotal = 'Highest Total',
  LowestTotal = 'Lowest Total',
  MaxPercentOff = 'Max Percent Off',
  MinPercentOff = 'Min Percent Off'
}

export interface AcceptedLiveOffers {
  id: string;
  version: number;
}

export interface offerBusinessPartner {
  offerId: string,
  seller: string,
  takenBy: string,
  madeBy: string
}