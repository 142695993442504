export interface IAuctionItem {
  CurrencyCode: string;
  WinningUser: string;
  CurrentPrice: number;
  CurrentQuantity: number;
  Description: string;
  EndDTTM: Date;
  Hits: number;
  ID: number;
  Increment: number;
  ActionCount: number;
  MediaResponse: any;
  OwnerUserName: string;
  StartDTTM: Date;
  Status: string;
  Title: string;
  Subtitle: string;
  TypeName: string;
  ReserveMet: boolean;
  IsWinning: boolean;
  IsWatching: boolean;
  OriginalPrice: number;
  MaxBid: number;

  NotificationMessages?: string;
  BidLoading?: boolean;
  WatchLoading?: boolean;
  HasBid: boolean;
}
