<div *ngIf="topService.loading" class="loader"></div>
<div *ngIf="selectedOrder.docNumber" class="full-order-global">
  <div class="top">
    <div class="header" [class.admin-header]="isSuper">
      <!--change 'true' -> 'isAdmin'-->
      <div class="header-left">
        <span class="back" routerLink="/user/orders"></span>
        Order #{{selectedOrder.docNumber}}
      </div>
      <ul class="header-info">
        <li>
          <div class="header-info__label">Order Status</div>
          <div class="header-info__value">
            <span [ngClass]="'badge _' + selectedOrder.orderStatus.replaceAll(' ', '')">
              {{ ordersService.toPascalCase(selectedOrder.orderStatus) }}
            </span>
          </div>
        </li>
        <!-- <li>
          <div class="header-info__label">Authorization</div>
          <div class="header-info__value">{{selectedOrder?.payment?.authorization}}</div>
        </li> -->
        <!-- <li class="_admin-show">
          <div class="header-info__label">Charge Reference</div>
          <div class="header-info__value" *ngIf="selectedOrder?.payment?.status === 'Applied'">
            {{selectedOrder?.payment?.charge}}</div>
        </li> -->
        <li class="_admin-show">
          <div class="header-info__label">Order Date</div>
          <div class="header-info__value">{{selectedOrder?.orderDate | date:"shortDate"}}</div>
        </li>
        <li class="_admin-show" *ngIf="selectedOrder?.payment?.id">
          <div class="header-info__label">Order Date Charged</div>
          <div class="header-info__value" *ngIf="selectedOrder?.payment?.status === 'Applied'">
            {{selectedOrder?.payment?.orderDate | date:"shortDate"}}</div>
        </li>
        <li class="_admin-show __admin-amount">
          <div class="header-info__label">
            <span *ngIf="selectedOrder?.payment?.status === 'Applied'">Charge </span>
            <span *ngIf="selectedOrder?.payment?.status === 'Authorize'">Authorize </span>
            Amount
          </div>
          <div class="header-info__value">{{selectedOrder?.payment?.amount | CustomCurrency}}</div>
        </li>


        <li class="_admin-hide" *ngIf="selectedOrder?.payment?.id">
          <div class="header-info__label">Date</div>
          <div class="header-info__value">
            {{selectedOrder?.payment?.orderDate | date:"shortDate"}}
          </div>
        </li>
        <li class="_admin-hide">
          <div class="header-info__label">
            <span *ngIf="selectedOrder?.payment?.status === 'Applied'">Charge </span>
            <span *ngIf="selectedOrder?.payment?.status === 'Authorize'">Authorize </span>
            Amount
          </div>
          <div class="header-info__value">{{selectedOrder?.payment?.amount | CustomCurrency}}</div>
        </li>


        <li *ngIf="selectedOrder?.payment?.id" class="__nickname">
          <div class="header-info__label">Card Nickname</div>
          <div class="header-info__value">{{selectedOrder?.payment?.cardName}}</div>
        </li>
      </ul>
      <ul class="header-controls">
        <li>
          <a (click)="!topService.loading?gotoShopping():''" class="btn continue-shop">Continue Shopping</a>
        </li>
        <li class="pdf-btn"
          *ngIf="isSuper && selectedOrder?.payment?.status === 'Authorize' && !selectedOrder.beingUpdated"
          (click)="showChargeModal()">
          <a class="charge-btn">
            <img src="assets/images/icons/profile/credit-card.svg" height="24" width="24" />
            <span>Charge</span>
          </a>
        </li>
        <li class="pdf-btn">
          <a (click)="!topService.loading?openPdf():''" class="download-btn">
            <img src="assets/images/icons/header/price-list-white.svg" height="24" width="24" />
            <span>Download</span>
          </a>
        </li>
        <li *ngIf="canEditOrder() && !selectedOrder.beingUpdated" class="pdf-btn">
          <a (click)="updateOrderConfirmDialog($event, selectedOrder)" class="edit-btn">
            <img src="assets/images/edit-order-white.svg" height="24" width="24" />
            <span>Edit Order</span>
          </a>
        </li>
        <li class="pdf-btn" *ngIf="canSplitOrCancelOrder() && !selectedOrder.beingUpdated"
          (click)="splitOrder(selectedOrder)">
          <a class="split-btn">
            <img src="assets/images/icons/rebranding/split-white.svg" height="24" width="24" />
          </a>
        </li>
        <li *ngIf="canSplitOrCancelOrder() && !selectedOrder.beingUpdated" class="pdf-btn">
          <a (click)="cancelConfirm(selectedOrder)" class="cancel-btn">
            <img src="assets/images/close-white.svg" height="24" width="24" />
            <span>Cancel Order</span>
          </a>
        </li>
      </ul>
    </div>
  </div>

  <div class="col-lg-12 invoice">

    <div
      style="width:1024px;height:1250px;min-width:1024px;min-height:1250px;background:url(/assets/images/back.jpg) no-repeat center top;margin:25px auto 0 auto;">

      <div style="clear:both;"></div>

      <div style="padding:20px">
        <div style="float: left;">

          <div style="margin-top:25px;">
            <img src="assets/images/logo-black.svg" height="42" width="157" />
          </div>
          <ul style="margin:35px 0 0 0;padding:0;list-style: none;line-height:20px;">
            <!-- <li>78 SW 7th Street
              <br/>Miami, FL 33130</li>
            <li>e-mail: support@hubx.com</li> -->
            <!-- <li><b>tel.</b> 408-703-4829</li> -->
          </ul>
        </div>

        <div style="padding-top:30px;">
          <div style="float:right;">

            <div style="float:left;padding-right:15px;">
              <ul style="padding:0;margin:0;list-style:none;text-align:left;">
                <li>
                  <b>Order No.:</b>
                </li>
                <li>
                  <b>Date:</b>
                </li>
                <li>
                  <b>Due Date:</b>
                </li>
                <li>
                  <b>Customer No.:</b>
                </li>
                <li>
                  <b>Customer Ref. No.:</b>
                </li>
                <!-- <li><b>Page No:</b></li> -->
              </ul>
            </div>

            <div style="float:right;padding-left:15px;border-left:rgba(0,0,0,0.9) thin solid;border-left-width:2px;">
              <ul style="padding:0;margin:0;list-style:none;text-align:left;">
                <li>{{selectedOrder.docNumber}} </li>
                <li>{{selectedOrder.orderDate | date:'shortDate'}}</li>
                <li>{{selectedOrder.dueDate ? (selectedOrder.dueDate | date:'shortDate') : ' - '}}</li>
                <li>{{selectedOrder.cardCode}}</li>
                <li *ngIf="selectedOrder.externalId">{{ordersService.getExternalId(selectedOrder)}}</li>
                <li *ngIf="!selectedOrder.externalId">--</li>
                <!-- <li>?? do we ?</li> -->
              </ul>
            </div>


          </div>
        </div>

        <div style="clear:both;"></div>

        <div style="margin-top:35px;">
          <div style="float:left;min-width:490px;width:490px;margin-right:2px;">
            <ul style="padding:0;margin:0;list-style:none;">
              <li style="background:rgba(0,0,0,0.9);padding:10px 0px 10px 10px;font-size:20px;color:#FFFFFF;border-radius: 12px;">BILL TO
              </li>
              <li style="margin:10px 0px 0px 5px;">{{selectedOrder.billingAddressCode}}</li>
              <li *ngFor="let shipping of shipping_address" style="margin:0px 0px 0px 5px;">{{shipping}}</li>
              <li></li>
            </ul>
          </div>

          <div style="float:left;min-width:490px;width:490px;">
            <ul style="padding:0;margin:0;list-style:none;">
              <li style="background:rgba(0,0,0,0.9);padding:10px 0px 10px 10px;font-size:20px;color:#FFFFFF;border-radius: 12px;">SHIP TO
              </li>
              <li style="margin:10px 0px 0px 5px;">{{selectedOrder.shippingAddressCode}}</li>
              <li *ngFor="let billing of billing_address" style="margin:0px 0px 0px 5px;">{{billing}}</li>
              <li></li>
            </ul>
          </div>
        </div>

        <div style="clear:both;"></div>

        <div style="margin-top:35px;border-bottom:#AAAAAA thin solid;border-bottom-width:1px;padding-bottom:7px;">
          <ul style="padding:0;margin:0;list-style:none;">
            <!-- <li style="float: left;margin-right:30px;font-size:20px;"><b>DIRECTION</b></li> -->
            <!-- <li style="padding-top:4px;"><b>S.C. TEXTILES INC</b></li> -->
          </ul>
          <div style="clear:both;"></div>
        </div>

        <div style="clear:both;"></div>

        <div style="float: left; min-width:490px; width: 490px; margin-right:2px; margin-top:5px;">
          <ul style="padding:0;margin:0;list-style: none;">
            <li>
              <b>Sales Employee:</b> {{selectedOrder.salesEmployee}}</li>
            <li>
              <b>Contact Name:</b> {{selectedOrder.contactName}}</li>
            <li>
              <b>Contact Email:</b> {{selectedOrder.contactEmail}}</li>
            <!-- <li><b>Contact TP:</b> ??????</li> -->
          </ul>
        </div>

        <div style="float:left; min-width: 490px; width: 490px; margin-top:5px;">
          <ul style="padding:0;margin:0;list-style: none;text-align:left;">
            <!-- <li><b>Cancelation Date:</b> ??????</li> -->
            <li>
              <b>Canceled:</b> {{selectedOrder.isCancelled ? 'Yes' : 'No'}}</li>
            <!-- <li><b>Ship Via:</b>??</li> -->
          </ul>
        </div>

        <div style="clear:both;"></div>

        <div style="margin-top:35px;">
          <table style="width:100%;border-spacing:0;">
            <thead>
              <tr>
                <th
                  style="border:#AAAAAA thin solid;border-width:1px;border-right:0;text-align:left;padding:7px 5px 7px 5px;">
                  Item No.</th>
                <th
                  style="border:#AAAAAA thin solid;border-width:1px;border-right:0;text-align:left;padding:7px 5px 7px 5px;">
                  Description</th>
                <th
                  style="border:#AAAAAA thin solid;border-width:1px;border-right:0;text-align:right;padding:7px 5px 7px 5px;">
                  EXW</th>
                <th
                  style="border:#AAAAAA thin solid;border-width:1px;border-right:0;text-align:right;padding:7px 5px 7px 5px;">
                  Unit Price</th>
                <th
                  style="border:#AAAAAA thin solid;border-width:1px;border-right:0;text-align:right;padding:7px 5px 7px 5px;">
                  Quantity</th>
                <th style="border:#AAAAAA thin solid;border-width:1px;text-align:right;padding:7px 5px 7px 5px;">Total
                </th>
              </tr>
            </thead>

            <style>
              table tbody tr:nth-child(odd) {
                background: #f7f7f7;
              }
            </style>

            <tbody>
              <tr *ngFor="let lines of selectedOrder.orderDetails">
                <td style="padding:5px;">{{lines.itemCode}}</td>
                <td style="width:430px;padding:5px;">{{ lines.description }}
                </td>
                <td style="padding:5px;text-align:right">{{lines.exwPoint}}</td>
                <td style="padding:5px;text-align:right">{{lines.unitPrice | currency:'USD':'symbol-narrow'}}</td>
                <td style="padding:5px;text-align:right">{{lines.quantity}}</td>
                <td style="padding:5px;text-align:right;">
                  {{ (lines.unitPrice * lines.quantity) | currency:'USD':'symbol-narrow'}}</td>
              </tr>

            </tbody>
          </table>
        </div>

        <div>
          <div style="padding-top:30px;">
            <div style="float:right;">

              <div style="display:inline-block;padding-right:15px;">
                <ul style="padding:0;margin:0;list-style:none;text-align:left;">
                  <li>Subtotal</li>
                  <li>Convenience Fee</li>
                  <li>Discount</li>
                  <li>Freight</li>
                  <li>Tax</li>
                  <!-- <li>Freight</li> -->
                  <li style="padding-top:5px;">
                    <b>Total Order Value</b>
                  </li>
                </ul>
              </div>

              <div class="price-block">
                <ul style="padding:0;margin:0;list-style:none;text-align:right;">
                  <li>{{ totalAmount() | currency:'USD':'symbol-narrow'}} </li>
                  <li>{{ this.selectedOrder?.payment?.fee | currency:'USD':'symbol-narrow'}}</li>
                  <li *ngIf="userDiscount>0">-{{ userDiscount | currency:'USD':'symbol-narrow' }}</li>
                  <li *ngIf="userDiscount===0"> {{ 0 | currency:'USD':'symbol-narrow' }}</li>
                  <li>{{selectedOrder.shippingCost | currency:'USD':'symbol-narrow'}}</li>
                  <li>{{ tax | currency:'USD':'symbol-narrow' }}</li>
                  <li style="border-top:rgba(0,0,0,0.9) thin solid;border-top-width:1px;padding-top:5px;">
                    {{ selectedOrder.orderTotal | currency }}
                  </li>
                </ul>
              </div>



            </div>
            <div style="clear:both"></div>
          </div>
        </div>

        <div *ngIf="bnkInfo" style="margin-top:0px;float: left;">
          <div style="margin-top:5px;">
            <ul style="padding:0;margin:0;list-style: none;">
              <li style="margin-bottom:10px;">
                <b>Beneficiary Bank</b>
                <br/><b>Bank Name:</b> {{bnkInfo.bankName}}
                <br/><b>Bank Address:</b> {{bnkInfo.address}}
                <br/>{{bnkInfo.city}}, {{bnkInfo.stateProvince}}, {{bnkInfo.country}} {{bnkInfo.zipCode}}
                <br/><b>Account Routing (ABA): </b> {{bnkInfo.routingNumber}}
                <br/><b>ACH: </b> {{bnkInfo.ach}}
                <br/><b>SWIFT/BIC CODE:</b> {{bnkInfo.swiftBicCode}}
                <br/><b>ACCOUNT Number:</b> {{bnkInfo.accountNumber}}
                <br/><b>Intermediary BIC:</b> {{bnkInfo.intermediaryBIC}}
                <br/><b>Beneficiary Company</b>
                <br/><b>Beneficiary Name:</b>  {{bnkInfo.beneficiaryName}}
                <br/><b>Beneficiary Address:</b> {{bnkInfo.beneficiaryAddress}}
                <br/>{{bnkInfo.beneficiaryCity}}, {{bnkInfo.beneficiaryStateProvince}}, {{bnkInfo.beneficiaryCountry}} {{bnkInfo.beneficiaryZipCode}}
              </li>
            </ul>
          </div>
          <div style="margin-top:24px;">
            <ul style="padding:0;margin:0;list-style: none;">
              <li><br />**SALES ORDER# MUST BE INCLUDED IN WIRE REFERENCE**</li>
            </ul>
          </div>
        </div>

        <div style="max-width:420px;float:right;margin-top:40px;text-align:justify;font-size:12px;">
          <span style="padding:5px 0px 5px 0px;width:100%; font-weight: bold;">
            <b>TERMS &amp; CONDITIONS</b>
          </span>
          <br/>
          <i>Failure to comply with the following terms and conditions will result in the termination of your
            account.</i>
          <br/>
          <br> It is our policy to comply with all US federal Laws and regulations, including the US Money
          Laundering Control Act, the Foreign Corrupt Practices Act, and all currency reporting
          requirements. HUBX will not accept third-party payments on behalf of any customer.  All your
          payments must be made in US Dollars, and you the customer represents and warrants that such
          dollar payments are derived from legitimate sources. We, also, reserve the right to terminate
          any account for any reason whatsoever.
        </div>

        <div style="clear:both;"></div>

        <div style="margin-top:35px;">
          <div style="padding:5px 0px 5px 0px;width:100%;">
            <ul
              style="border-bottom:#AAAAAA thin solid;border-bottom-width:1px;border-top:#AAAAAA thin solid;border-top-width:1px;padding:0;margin:0;list-style:none;">
              <li style="float:left;">
                <span style="font-weight:bold;">Remarks:</span>&nbsp;&nbsp;{{selectedOrder.comments}}</li>
              <div style="clear:both;"></div>
            </ul>
          </div>


          <div style="float:left;">
            <div style="margin-top:35px;">
              <ul style="padding:0;margin:0;list-style: none;">
                <li>PLEASE NOTE: All Dell part numbers</li>
                <li>ending in PC, SA, or SD can only be</li>
                <li>resold to the following countries:</li>
                <li>USA, CANADA, ISRAEL, THE NETHERLANDS,</li>
                <li>SAUDI ARABIA, UNITED ARAB EMIRATES</li>
                <li>&nbsp;</li>
              </ul>
            </div>
          </div>

          <div style="max-width:500px;float:right;margin-top:35px;text-align:justify;font-size:12px;">
            <ul style="list-style:disc;">
              <li>No products sold by hubx.com are manufactured by HUBX, however, certain products may
                be covered by a manufacturer's limited warranty.  HUBX assigns to the customer any warranty
                of the manufacturer, and customer acknowledges that it shall have recourse only under such
                warranties and only against the manufacturer. HUBX makes no representation or express
                warranty with respect to the product, except those stated herein.</li>
              <li>We guarantee to ship the part numbers you order. It is your responsibility to verify the
                specifications with the manufacturer. </li>
              <li>No cancellation of orders or product returns are permitted. All Sales are final.  If HUBX, in its
                sole discretion, permits the cancellation of an order, such order shall be subject to a 15%
                restocking fee. </li>
              <li>A finance charge of 1.5% per month will be imposed on past due amounts. Unless prohibited
                by applicable law, HUBX reserves the right to offset any credit amounts held in a customer’s
                account against any amounts due to HUBX.</li>
            </ul>
          </div>
        </div>

        <div style="clear:both;"></div>

        <div *ngIf="selectedOrder.payment?.id" style="margin-top:35px;">
          <div style="padding: 30px 28px 34px;width:100%;background:rgba(26, 26, 26, 1); border-radius: 12px;">
            <div style="width: 64px;float: left;margin-right: 28px;margin-top: 6px;">
              <img src="assets/images/order/64-terms.svg" height="64" width="64" />
            </div>
            <ul style="padding:0;margin:0;list-style:none;max-width: 598px;">
              <li style="font-size:28px;line-height:36px;color:#FFFFFF;font-family: 'HelveticaNeueCyr-Bold';">All Purchases are Subject to the </li>
              <li style="font-size:28px;line-height:36px;color:#FFFFFF;font-family: 'HelveticaNeueCyr-Bold';">following Terms and Conditions</li>
            </ul>
          </div>


          <div style="float:left;width: 50%;padding-right: 12px;">
            <div style="margin-top:64px;">
              <ul style="padding:0;margin:0;list-style: none;font-size:14px;line-height: 20px;text-align:justify;">
                <li>By placing a check mark in the adjacent box, you are indicating your </li>
                <li>acceptance and agreement with the foregoing terms and conditions, </li>
                <li>including a 3% Convenience Fee for all online purchases by card credit</li>
                <li>(incurred as a result of the ease and convenience associated with</li>
                <li>conducting this transaction online), and acknowledging that you are a</li>
                <li>legally authorized user of this credit card.</li>
                <li style="height: 20px;width: 100%;"></li>
                <li>The prices and available quantities of certain Products are subject to</li>
                <li>fluctuate due to market conditions beyond our control. We do not provide</li>
                <li>warranties for Products, other than limited warranties for certain select</li>
                <li>Products clearly identified on such Product’s listing ID. However, we do</li>
                <li>not provide or administer any warranty service. To the extent your Product</li>
                <li>is covered by any warranty, we would refer you to the warranty information</li>
                <li>contained in your Product’s original packaging. It is your sole</li>
                <li>responsibility to verify the accuracy of your shipping information, part</li>
                <li>numbers, and product specifications.</li>
              </ul>
            </div>
          </div>

          <div style="float: right;width: 50%;margin-top:64px;text-align:justify;font-size:14px;line-height: 20px;padding-left: 12px;">
            <div style="padding:5px 0px 5px 0px;width:100%;border: 1px solid rgba(26, 26, 26, 1); border-radius: 8px;">
              <ul style="padding:0;margin:0;list-style:none;padding-left: 14px;font-family: 'HelveticaNeueCyr-Bold';">
                <li>NO RETURNS OR REFUNDS. ALL SALES ORDERS ARE FINAL</li>
                <li>– A 15% RESTOCKING FEE APPLIES ALL CANCELLATIONS.</li>
              </ul>
            </div>
            <ul style="padding:0;margin:0;list-style:none; margin-top: 18px;padding-left: 14px;">
              <li>In an effort to provide you the ease and convenience of making online</li>
              <li>purchases of our Products, we allow our customers to make purchases by</li>
              <li>credit card, subject to a 3% Convenience Fee. HUBX reserves the right to</li>
              <li>cancel any Sales Order if confirmation of payment is not received within</li>
              <li>72 business hours. At the time of the receipt of your shipment, any</li>
              <li>damage to exterior packaging must be noted prior to signing for</li>
              <li>merchandise. If the carrier does not have such notes an insurance claim</li>
              <li>will not be filed, and you will be deemed to have inspected and accepted</li>
              <li>the Products. Orders may take up to 2 business days, after credit release,</li>
              <li>to pick and pack.</li>
            </ul>
          </div>
        </div>
        <div style="clear:both;"></div>

        <div style="margin-top:72px;box-shadow: 0 6px 20px 0 rgba(0,0,0,0.19); border-radius: 12px; overflow: hidden; padding-bottom: 56px;"
        *ngIf="selectedOrder.payment?.id">
          <div style="padding: 28px 28px 29px;width:100%;background:rgba(26, 26, 26, 1); border-radius: 12px;">
            <div style="width: 64px;float: left;margin-right: 28px;">
              <img src="assets/images/order/64-check.svg" height="64" width="64" />
            </div>
            <ul style="padding:0;margin:0;list-style:none;max-width: 598px;margin-top: 3px;">
              <li style="font-size: 21.6px;line-height: 28.8px;color:#FFFFFF;font-family: 'HelveticaNeueCyr-Bold';">I agree to terms and conditions of placing order</li>
              <li style="font-size: 21.6px;line-height: 28.8px;color:#FFFFFF;font-family: 'HelveticaNeueCyr-Bold';">and I am an authorized agent to place order.</li>
            </ul>
          </div>


          <div style="float:left;width: 390px;padding-right: 12px;margin-left: 120px;">
            <div style="margin-top:50px;">
              <ul style="padding:0;margin:0;list-style: none;text-align:left;">
                <li style="font-size: 13px; color: #1A1A1A; line-height: 18px;">Company Name</li>
                <li style="font-size: 18px; color: #1A1A1A; line-height: 22px; font-family: 'HelveticaNeueCyr-Bold'; margin-top: 4px;">
                  {{ selectedOrder.cardName }}
                </li>

                <li style="font-size: 13px; color: #1A1A1A; line-height: 18px; margin-top: 31px;">Electronic Authorized Agent Signature</li>
                <li style="font-size: 18px; color: #1A1A1A; line-height: 22px; font-family: 'HelveticaNeueCyr-Bold'; margin-top: 4px;">
                  {{ selectedOrder.payment.firstName }} {{ selectedOrder.payment.lastName }}
                </li>

                <li style="font-size: 13px; color: #1A1A1A; line-height: 18px; margin-top: 30px;">IP Address</li>
                <li style="font-size: 18px; color: #1A1A1A; line-height: 22px; font-family: 'HelveticaNeueCyr-Bold'; margin-top: 4px;">
                  {{ selectedOrder.payment.ipAddress }}
                </li>
              </ul>
            </div>
          </div>
          <div style="float:left;width: 220px;padding-right: 12px;padding-left: 14px;">
            <div style="margin-top:50px;">
              <ul style="padding:0;margin:0;list-style: none;text-align:left;">
               <li style="font-size: 13px; color: #1A1A1A; line-height: 18px;">HUBX Business Partner</li>
               <li style="font-size: 14px; color: #1A1A1A; line-height: 17px; font-family: 'HelveticaNeueCyr-Bold'; margin-top: 8px;">
                #{{  selectedOrder.cardCode }}
              </li>

               <li style="font-size: 13px; color: #1A1A1A; line-height: 18px;margin-top: 32px;">HUBX Order</li>
               <li style="font-size: 14px; color: #1A1A1A; line-height: 17px; font-family: 'HelveticaNeueCyr-Bold'; margin-top: 8px;">
                #{{ selectedOrder.docNumber }}
              </li>

               <li style="font-size: 13px; color: #1A1A1A; line-height: 18px;margin-top: 31px;">Credit Card</li>
               <li style="font-size: 14px; color: #1A1A1A; line-height: 17px; font-family: 'HelveticaNeueCyr-Bold'; margin-top: 6px;">
                <span style="font-size: 14px; color: #1A1A1A; line-height: 20px;vertical-align: middle;font-family: 'HelveticaNeueCyr-Bold';padding-right:9px;">
                  **** {{ selectedOrder.payment.cardLast4Digits }} </span><img [src]="'assets/images/order/' + selectedOrder.payment.cardType + '.svg'" height="20" width="62" />
              </li>
              </ul>
            </div>
          </div>
          <div style="float:left;width: 150px;padding-right: 12px;">
            <div style="margin-top:50px;">
              <ul style="padding:0;margin:0;list-style: none;text-align:left;">
                <li style="font-size: 13px; color: #1A1A1A; line-height: 18px;">Date</li>
                <li style="margin-top: 7px;font-size: 14px; color: #1A1A1A; line-height: 17px; font-family: 'HelveticaNeueCyr-Bold'; ">
                  {{ selectedOrder.payment.orderDate | date:'mediumDate' }}
                </li>

                <li style="font-size: 13px; color: #1A1A1A; line-height: 18px;margin-top: 31px;">Time</li>
                <li style="font-size: 14px; color: #1A1A1A; line-height: 17px; font-family: 'HelveticaNeueCyr-Bold'; margin-top: 8px;">
                  {{ selectedOrder.payment.orderDate | date:'H:mm' }}
                </li>

                <li style="font-size: 13px; color: #1A1A1A; line-height: 18px;margin-top: 33px;">Authorized Amount</li>
                <li style="font-size: 14px; color: #1A1A1A; line-height: 17px; font-family: 'HelveticaNeueCyr-Bold'; margin-top: 8px;">
                  ${{ selectedOrder.payment.amount }}
                </li>
              </ul>
            </div>
          </div>

        </div>

      </div>
    </div>
  </div>

  <!-- EDIT ORDER CONFIRM POPUP -->
  <div class="edit-confirm" *ngIf="isShowModalOrShowCancelModal()">
    <div class="add-to-wrap animated" [class.zoomIn]="animateIn" [class.zoomOut]="animateOut">
      <!-- <div (click)="showModal = !showModal" class="close"></div> -->
      <div class="message">
        <div *ngIf="!this.cancelError">
          {{ popUpTextMessages() }}
        </div>
        <div *ngIf="this.cancelError" class="cancel-error">
          {{ popUpTextMessages() }}
        </div>
      </div>

      <div class="bottom-controls">
        <div>
          <div class="container">
            <div class="row">
              <div class="btn btn-cancel col-xs-6 col-md-6">
                <button *ngIf="showModal" (click)="closeModal()" class="cancel"
                  [disabled]="topService.loading">Cancel</button>
                <button *ngIf="showCancelModal" [ngClass]="{'hide': cancelError}" (click)="closeCancelConfirm()"
                  class="cancel" [disabled]="topService.loading">No</button>
              </div>
              <div class="btn btn-continue col-xs-6 col-md-6">
                <button *ngIf="showModal" class="continue" [disabled]="topService.loading"
                  (click)="editOrder(selectedOrder.docEntry);">Continue</button>
                <button *ngIf="showCancelModal" [ngClass]="{'hide': cancelError}" (click)="cancelOrderYes()"
                  class="continue" [disabled]="topService.loading">Yes</button>
              </div>
              <div class="btn btn-error  col-xs-12 col-md-12">
                <button *ngIf="cancelError" (click)="closeCancelConfirm()" [disabled]="topService.loading" class="cancel">Got it</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="charge-modal" *ngIf="showChargeOrder">
    <div class="charge-modal-dialog">
      <div class="charge-modal-content">
        <div class="charge-modal-body">
          Charge Order # {{ selectedOrder.docNumber }}<br />{{ selectedOrder.orderTotal | CustomCurrency }}
        </div>
        <div class="charge-modal-footer">
          <button type="button" class="button-secondary" (click)="showChargeOrder = false;">Cancel</button>
          <button type="button" class="button-primary" (click)="chargeOrder()"
            [disabled]="topService.loading">Charge</button>
        </div>
      </div>
    </div>
  </div>
  <div class="charge-modal" *ngIf="showChargedOrder">
    <div class="charge-modal-dialog">
      <div class="charge-modal-content">
        <div class="charge-modal-body">
          Order # {{ selectedOrder.docNumber }} has been <br />successfully charged
          {{ selectedOrder.orderTotal | CustomCurrency }}
        </div>
        <div class="charge-modal-footer">
          <button type="button" class="button-primary" (click)="closeChargedModal()">Got it</button>
        </div>
      </div>
    </div>
  </div>
</div>

<app-error-modal [bodyText]="errorModal.bodyText" [title]="errorModal.title" [isError]="errorModal.isShown"
  (onConfirmError)="hideError()"></app-error-modal>
