import { AttrtypeEnum } from '../enums/AttrTypeEnum';
import { AttrDataTypeEnum } from '../enums/AttrDataTypeEnum';

export interface IReqAttribute {
    id?: string;
    name?: string;
    attrType?: AttrtypeEnum;
    attrDataType?: AttrDataTypeEnum;
    values?: string[];
}
