import { Component, Input, Output, OnInit, EventEmitter, OnDestroy } from '@angular/core';
import { ConfirmationPopupSettings } from '../interfaces/IConfirmationPopupSettings';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { NgIf, NgClass } from '@angular/common';

@Component({
    selector: 'app-confirmation-popup',
    templateUrl: './confirmation-popup.component.html',
    styleUrls: ['./confirmation-popup.component.scss'],
    standalone: true,
    imports: [NgIf, NgClass]
})
export class ConfirmationPopupComponent implements OnInit, OnDestroy {

  @Input() settings: ConfirmationPopupSettings;
  @Input() isFromOfferCancellation: boolean = false;
  @Input() isMobile = false;
  @Output() onCancel = new EventEmitter();
  @Output() onAccept = new EventEmitter();
  @Output() onReject = new EventEmitter();
  zoomOut = false;

  constructor( private sanitizer: DomSanitizer,) { }

  ngOnInit(): void {
      this.settings.bodyText = this.settings?.bodyText || '';
      this.settings.headerText = this.settings?.headerText || '';
      this.settings.zoomIn = this.settings?.zoomIn || true;
      this.settings.zoomOut = this.settings?.zoomOut || true;
  }

  ngOnDestroy(): void {
    this.zoomOut = false;
  }

  reject() {
    this.onReject.emit();
    this.close();
  }

  cancel() {
    this.onCancel.emit();
    this.close();
  }

  accept() {
    this.onAccept.emit();
    this.close();
  }

  close() {
    if (this.settings.zoomOut) {
      this.zoomOut = true;
      setTimeout(() => {
        this.settings.show = false;
      }, 200);
      return;
    } 
    this.settings.show = false;
  }

  domSanitizer(text: string): SafeHtml {
    const html: SafeHtml = this.sanitizer.bypassSecurityTrustHtml(text);
    return html;
  }
}
