import * as enums from './../../enums/enum';

export interface CounterOfferRequest {
  instanceId: string,
  quantity: number,
  price: number,
  isFinal: boolean,
  goodFor: number,
  effectivePrice: number,
  version: number
}

export interface CounterOfferResponse {
  id: string,
  version: number,
  vendorItemId: string,
  customerId: string,
  vendorId: string,
  status: enums.FullOfferStatusEnum,
  quantity: number,
  price: number,
  expiresOn: Date,
  goodFor: number,
  isFinal: boolean,
  createdOn: Date,
  createdBy: string,
  updatedOn: Date,
  updatedBy: string;
  eventId: string;
}

export interface CounterOfferErrorResponse {
  errorCode: number,
  description?: string
}

export interface PriceOptions {
  price: number,
  percent: string,
  dropdownOptionDisabled: boolean
}
