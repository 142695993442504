import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { saveAs as importedSaveAs } from 'file-saver';
import { DropdownCategoriesComponent } from '../../buyer/categories/dropdown-categories.component';
import { SharedSource } from '../../core/shared-source';
import { ITopNav } from '../../buyer/interfaces/ITopNav';
import { StatusFilterEnum } from '../../buyer/enums/statusFilterEnum';
import { BidStatusEnum } from '../../buyer/enums/bidStatusEnum';
import { GuestService } from '../services/guest.service';
import { WhiteLogosPipe } from '../pipes/white-logos.pipe';
import { AddToCartModalComponent } from '../ui-components/add-to-cart-modal/add-to-cart-modal.component';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { ItemFilterMobileComponent } from '../item-filter/item-filter-mobile/item-filter-mobile.component';
import { NgIf, NgClass, NgFor } from '@angular/common';

@Component({
    selector: 'app-bottom-nav-mobile',
    templateUrl: './bottom-nav-mobile.component.html',
    styleUrls: ['./bottom-nav-mobile.component.scss'],
    standalone: true,
    imports: [NgIf, ItemFilterMobileComponent, FormsModule, RouterLink, RouterLinkActive, NgClass, NgFor, AddToCartModalComponent, WhiteLogosPipe]
})
export class BottomNavMobileComponent extends DropdownCategoriesComponent implements OnInit, OnDestroy {
  @Input() showBackBtn = false;
  date = new Date();
  fileName = 'HUBX-Price-List-' + this.date.toLocaleString() + '.xlsx';
  isMainMenuOpen = false;
  isMainMenuAnimated = false;
  isCategoriesOpen = false;
  isCategoriesAnimated = false;
  selectedCategoryId = null;
  justSoldCount: boolean;
  isFilterOpen = false;

  scrollPosition = 0;

  ngOnInit() {
    this.cartService.totalUnits();
  }

  lockScroll(): void {
    this.scrollPosition = window.pageYOffset;
    document.documentElement.classList.add('is-modal');
  }

  unlockScroll(handleScrollPosition): void {
    document.documentElement.classList.remove('is-modal');
    if (handleScrollPosition) {
      window.scrollTo(0, this.scrollPosition);
    }
    this.scrollPosition = 0;
  }

  openMainMenu(): void {
    this.isMainMenuOpen = true;
    this.isCategoriesOpen = false;
    this.lockScroll();
  }

  hideMainMenu(): void {
    this.isMainMenuOpen = false;
    this.unlockScroll(true);
  }

  openCategories(): void {
    this.isCategoriesOpen = true;
    this.isMainMenuOpen = false;
    this.lockScroll();
  }

  hideCategories(): void {
    this.isCategoriesOpen = false;
    this.unlockScroll(true);
  }

  toggleSubMenu(id: number): void {
    this.selectedCategoryId = this.selectedCategoryId === id ? null : id;
  }

  getPriceList(): void {
    this.userService.isGuest ? this.userService.guestPop() : this.topService.getPriceList();
  }

  goToCart(): void {
    this.userService.isGuest ? this.userService.guestPop() : this.cartService.goToCart();
  }

  goBack() {
    if (this.router.url.match(/offers/) && this.offersService.selectedOffer?.id) {
      var isBuyerPortal = ['BUYER', 'SALES', 'SUPER'].includes(sessionStorage.getItem('user_role'));
      const url = `${isBuyerPortal ? '' : 'vendor'}/user/offers`;
      this.location.replaceState(url);
      this.offersService.selectedOffer = null;
      window.scrollTo(0, 0);
      return;
    }

    if (!this.auctionsService.isDetailsPage) {
      this.location.back();
    } else {
      const urlPerRole = (this.userService.isGuest) ? '/guest' : '';
      this.router.navigate([`${urlPerRole}/auctions`]);
    }

  }

  showFilter(): void {
    const currentUrl = this.router.url;
    if (currentUrl.match(/notifications/)) {
      this.notifBuyerService.showHeaderMobile = true;
    } else if (currentUrl.match(/auctions/)) {
      this.auctionsService.showMobileHeader = true;
    } else {
      this.itemFilterService.isFilterMobileOpen = true;
    }
    this.lockScroll();
  }

  hideFilter(): void {
    this.itemFilterService.isFilterMobileOpen = false;
    this.unlockScroll(false);
  }

  searchPastPurchase() {
    this.pubSubService.sharedSubject.next({ name: SharedSource.pastPurchaseSearch, data: true });
  }

  isKeyEnter(event) {
    if (event.key === 'Enter') {
      this.searchPastPurchase();
    }
  }

  navigateTo() {
    if (this.sessionService._user_role === 'BUYER') {
      return '/buyer/home';
    } else if (this.sessionService._user_role === 'GUEST') {
      return '/guest/home';
    }
  }

  navigateToCorporate(url: string): void {
    this.hideMainMenu();
    this.sharedService.staticHtmlUrl = url;
    this.sharedService.isCorpSite = true;
    this.sharedService.isStaticHtml = true;
    this.router.navigate(['/' + url]);
  }

  cancelDownload() {
    this.topService.cancelPriceListDownload();
  }

  parentClicked(catNav: ITopNav, index: number) {
    this.hovered = catNav;
    this.toggleSubMenu(index);
    if (catNav.navChilds?.length == 0) {
      this.parentNav(catNav);
      this.hideCategories();
    }
  }

  goToPastPurchases() {
    return `${this.userService.getUrlByRole()}/past_purchases`;
  }

  goToAuctions() {
    return `${this.userService.getUrlByRole(true)}/auctions/`;
  }

  goToOutlets() {
    return `${this.userService.getUrlByRole(true)}/outlets/`;
  }

  gotoJustSold() {
    return `${this.userService.getUrlByRole()}/just_sold`;
  }

  ngOnDestroy() {
    document.documentElement.classList.remove('is-modal');
    this.scrollPosition = 0;
    window.scrollTo(0, this.scrollPosition);
  }

  isBuyerPortal(_cc?: string) {
    let result = ['BUYER', 'SALES', 'SUPER'].includes(sessionStorage.getItem('user_role'));
    if (_cc) {
      result = (result && (this.userService.isCreditCardAllowed));
    }
    return result;
  }
}
