import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';

import { PubSubService } from '../core/pubsub.service';
import { SharedSource } from '../core/shared-source';
import { IDropdown } from '../vendor/interface/IDropdown';
import { PageErrorService } from '../page-error/page-error.service';
import { IErrorModal } from '../vendor/interface/IErrorModal';
import { IProfile } from './interfaces/IProfile';

import * as models from '../buyer/interfaces/model';
import { IBuyerErrorModal } from '../buyer/interfaces/IBuyerErrorModal';
import { ITopNav } from '../buyer/interfaces/ITopNav';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IJustSoldMessage } from './interfaces/IJustSoldMessage';

import { IItem, IAuctionItem } from '../buyer/interfaces/model';
import { INotificationMessage } from './interfaces/INotificationMessage';
import { NotificationTypesEnum } from './interfaces/INotificationDetail';
import { IOfferEventMessage, IOfferFeatureSwitchedMessage, IOfferNotificationMessage, IOfferSeenMessage, OfferNotificationTypesEnum } from './interfaces/IOfferNotificationMessage';

export const nonItemRoutesRgx =
  new RegExp(/\/home$|home\?listing|redirectTo|outlets|user_filters\/[\w-]{30}|cart|full-order|thank-you|page-error|live_offers/);

@Injectable()
export class SharedService {
  private _editOrderMode = false;
  private _noOrders = false;
  private _catalogViewSwitch: boolean;
  private _vendorLoggedIn = '';
  private _orderSearch: models.IOrder;
  private _dropdownData: IDropdown;
  private _profileData: IProfile;
  private _cartData: boolean;
  private _mobileSize: boolean;
  private _isMobileSortingPopup: boolean;
  private _isShowDetailedPopUp: boolean;
  private _topCategory: ITopNav[];
  private _breadCrumbsTitle: string;
  private _navId: string;
  private _currentComponent: string;
  private _itemsScrollPosition = 0;
  private _isLoggedInInternal: boolean;
  private _isLoggedInGuest: boolean;
  private _itemsScrollLeftPosition = 0;
  private _justSoldValues: IJustSoldMessage = {};
  private _isJustSoldSelected: boolean;
  private _showPreCart: boolean = false;
  private _navigationReady = false;
  private _isSearch: boolean;
  private _topFiltersOutletChanged: ITopNav[];
  private _isCorpSite = false;
  private _forgotPwdFailed: boolean;

  private _itemNewPrice: IItem;
  public gradingGuideUrl = '';
  public isGradingGuideOpen = false;
  isInitialState = true;
  isClearedAll = true;
  isStaticHtml = false;
  staticHtmlUrl = '';
  gradingGuideHtmlPath = '';
  isLogoutPage = false;
  isAnimationTicking = true;
  pastPurchasesTotal: number;
  corporateSiteSelectedLink = '';

  constructor(
    private pubSubService: PubSubService,
    private pageErrorService: PageErrorService,
    private router: Router,
    private http: HttpClient,
    private zone: NgZone
  ) { }

  set isCorpSite(v) {
    if (!v) {
      document.body.style.paddingTop = '';
      document.body.style.overflowY = '';
    } else {
      // document.body.style.paddingTop = '72px';
      document.body.style.paddingBottom = '0px';
      document.body.style.overflowY = 'auto';
    }
    this._isCorpSite = v;
  }

  get isCorpSite() {
    return this._isCorpSite;
  }

  /**
    *  guest logged in ( ) - Getter & Setter
   */

  set isLoggedInGuest(_isLoggedInGuest: boolean) {
    this._isLoggedInGuest = _isLoggedInGuest;
    this.pubSubService.sharedSubject.next({ name: SharedSource.LoggedInGuest, data: this._isLoggedInGuest });
    // this._cartData = !this._cartData;
  }

  get isLoggedInGuest(): boolean {
    return this._isLoggedInGuest;
  }

  /**
  *  Is Just Sold Selected ( ) - Getter & Setter
 */

  set isJustSoldSelected(_isJustSoldSelected: boolean) {
    this._isJustSoldSelected = _isJustSoldSelected;
    this.pubSubService.sharedSubject.next({ name: SharedSource.IsJustSoldSelected, data: this._isJustSoldSelected });
  }

  get isJustSoldSelected(): boolean {
    return this._isJustSoldSelected;
  }

  /**
  *  trigger when user is searching - Getter & Setter
 */

  set isSearch(isSearch: boolean) {
    this._isSearch = isSearch;
    this.pubSubService.sharedSubject.next({ name: SharedSource.IsSearch, data: this._isSearch });
  }

  get isSearch(): boolean {
    return this._isSearch;
  }

  /**
    *  user logged in ( Admin,Vendor,Buyer,Sales) - Getter & Setter
   */

  set isLoggedInInternal(isLoggedInInternal: boolean) {
    this._isLoggedInInternal = isLoggedInInternal;
    this.pubSubService.sharedSubject.next({ name: SharedSource.LoggedInInternal, data: this._isLoggedInInternal });
    // this._cartData = !this._cartData;
  }

  get isLoggedInInternal(): boolean {
    return this._isLoggedInInternal;
  }

  /**
    *  Get just sold values
  */
  set justSoldValues(_justSoldValues: IJustSoldMessage) {
    this._justSoldValues = _justSoldValues;
    this.pubSubService.sharedSubject.next({ name: SharedSource.JustSoldValues, data: this._justSoldValues });
  }

  get justSoldValues(): IJustSoldMessage {
    return this._justSoldValues;
  }

  /**
   * Cart Data - Getter & Setter
  */
  set cartData(_cartData: boolean) {
    this._cartData = _cartData;
    this.pubSubService.sharedSubject.next({ name: SharedSource.cartFetch, data: this._cartData });
    this._cartData = !this._cartData;
  }

  get cartData(): boolean {
    return this._cartData;
  }

  /**
   * Nav Id - Getter & Setter
  */
  set navId(navId: string) {
    this._navId = navId;
    this.pubSubService.sharedSubject.next({ name: SharedSource.navChanged, data: this._navId });
  }

  get navId(): string {
    return this._navId;
  }

  /**
   * Breadcrumbs title - Getter & Setter
   */
  set breadCrumbsTitle(breadcrumbsTitle: string) {
    this._breadCrumbsTitle = breadcrumbsTitle;
    this.pubSubService.sharedSubject.next({ name: SharedSource.breadCrumbsTitle, data: this._breadCrumbsTitle });
  }

  get breadCrumbsTitle(): string {
    return this._breadCrumbsTitle;
  }

  /**
   * Top Category - Getter & Setter
   */
  set topCategory(topCategory: ITopNav[]) {
    this._topCategory = topCategory;
    this.pubSubService.sharedSubject.next({ name: SharedSource.topCategory, data: this._topCategory });
  }

  get topCategory(): ITopNav[] {
    return this._topCategory;
  }

  /**
   * Mobile Sorting Popup - Getter & Setter
   */
  set isMobileSortingPopup(_isMobileSortingPopup: boolean) {
    this._isMobileSortingPopup = _isMobileSortingPopup;
    this.pubSubService.sharedSubject.next({ name: SharedSource.mobileSortingPopUpOpen, data: this._isMobileSortingPopup });
    this._isMobileSortingPopup = !this._isMobileSortingPopup;
  }

  get isMobileSortingPopup(): boolean {
    return this._isMobileSortingPopup;
  }

  /**
  * Mobile Item Details Popup - Getter & Setter
  */
  set isShowDetailedPopUp(_isShowDetailedPopUp: boolean) {
    this._isShowDetailedPopUp = _isShowDetailedPopUp;
    this.pubSubService.sharedSubject.next({ name: SharedSource.showDetailedPopUpOpen, data: this._isShowDetailedPopUp });
    this._isShowDetailedPopUp = !this._isShowDetailedPopUp;
  }

  get isShowDetailedPopUp(): boolean {
    return this._isShowDetailedPopUp;
  }

  /**
   * Mobile Size - Getter & Setter
   */
  set mobileSize(_mobileSize: boolean) {
    this._mobileSize = _mobileSize;
    this.pubSubService.sharedSubject.next({ name: SharedSource.mobileSize, data: this.mobileSize });
    this._mobileSize = !this._mobileSize;
  }

  get mobileSize(): boolean {
    return this._mobileSize;
  }

  /**
   * Edit Order Mode - Getter & Setter
   */
  set editOrderMode(editOrderMode: boolean) {
    this._editOrderMode = editOrderMode;
    this.pubSubService.sharedSubject.next({ name: SharedSource.editOrderMode, data: this.editOrderMode });
  }

  get editOrderMode(): boolean {
    return this._editOrderMode;
  }

  /**
   * When there are no orders - Getter & Setter
   */
  set noOrders(noOrders: boolean) {
    this._noOrders = noOrders;
    this.pubSubService.sharedSubject.next({ name: SharedSource.noOrders, data: this.noOrders });
  }

  get noOrders(): boolean {
    return this._noOrders;
  }


  /**
   * Catalog View Switch - Getter & Setter
   */
  set catalogViewSwitch(catalogViewSwitch: boolean) {
    this._catalogViewSwitch = catalogViewSwitch;
    this.pubSubService.sharedSubject.next({ name: SharedSource.catalogViewSwitch, data: catalogViewSwitch });
  }

  get catalogViewSwitch(): boolean {
    return this._catalogViewSwitch;
  }

  /**
   * Profile Data - Getter & Setter
   */
  set profileData(profileData: IProfile) {
    this._profileData = profileData;
    this.pubSubService.sharedSubject.next({ name: SharedSource.profileData, data: this.profileData });
  }

  get profileData(): IProfile {
    return this._profileData;
  }

  /**
   * Vendor Logged In - Getter & Setter
   */
  set vendorLoggedIn(vendorLoggedIn: string) {
    this._vendorLoggedIn = vendorLoggedIn;
    this.pubSubService.sharedSubject.next({ name: SharedSource.vendorLoggedIn, data: this._vendorLoggedIn });
  }

  get vendorLoggedIn(): string {
    return this._vendorLoggedIn;
  }

  /**
   * Order Search - Getter & Setter
   */
  UorderSearch(orderSearch: models.IOrder): void {
    this._orderSearch = orderSearch;
    this.pubSubService.sharedSubject.next({ name: SharedSource.orderSearch, data: this._orderSearch });
  }

  get orderSearch(): models.IOrder {
    return this._orderSearch;
  }

  /**
   * Dropdown - Getter & Setter
   */
  public setDropdown(dropdownData: IDropdown): void {
    this._dropdownData = dropdownData;
    this.pubSubService.sharedSubject.next({ name: SharedSource.dropdownDirective, data: this._dropdownData });
  }

  get getDropdown(): IDropdown {
    return this._dropdownData;
  }

  /**
  * Current Component - Getter & Setter
  */
  set currentComponent(currentComponent: string) {
    this._currentComponent = currentComponent;
    this.pubSubService.sharedSubject.next({ name: SharedSource.vendorComponentUpdated, data: this._currentComponent });
  }

  get currentComponent(): string {
    return this._currentComponent;
  }

  /**
   * Scrolling from modal overlay - Getter & Setter
   */

  set scrollOnOverlay(itemsScrollPosition: number) {
    this._itemsScrollPosition = itemsScrollPosition;
    this.pubSubService.sharedSubject.next({ name: SharedSource.itemsScrollPosition, data: itemsScrollPosition });
  }

  get scrollOnOverlay(): number {
    return this._itemsScrollPosition;
  }

  /**
   * Item Price Change - Getter & Setter
   */

  set itemNewPrice(_itemNewPrice: IItem) {
    this._itemNewPrice = _itemNewPrice;
    this.pubSubService.sharedSubject.next({ name: SharedSource.newPriceUpdated, data: _itemNewPrice });
  }

  get itemNewPrice(): IItem {
    return this._itemNewPrice;
  }

  /**
   * Forgot Password Request Failed - Getter & Setter
   */

  set forgotPwdFailed(_requestFailed: boolean) {
    this._forgotPwdFailed = _requestFailed;
    this.pubSubService.sharedSubject.next({ name: SharedSource.forgotPasswordRequestFailed, data: _requestFailed });
  }

  get forgotPwdFailed(): boolean {
    return this._forgotPwdFailed;
  }

  /**
   * Vendor & Buyer Error Handlers
   */
  handleVendorHttpError(err: any, errorModal: IErrorModal, portal?: string): void {
    this.pageErrorService.errorCode = err.status;
    if (err.status === 400 || err.status === 409 || err.status === 404 || err.status === 500) {
      if (!err.error) {
        errorModal.isError400 = true;
        errorModal.errorMessage400 = err.message;
      } else {
        errorModal.isError400 = true;
        try {
          errorModal.errorMessage400 = err.error.error;
        } catch (e) {
          try {
            errorModal.errorMessage400 = err.error.message;
          } catch (e) {
            errorModal.errorMessage400 = 'Unknown error ';
          }
        }
      }
    } else {
      this.pageErrorService.errorMessage = err.error_description;
      this.router.navigate(['page-error', { portal: portal }]);
    }
  }

  handleBuyerHttpError(err: any, errorModal: IBuyerErrorModal, errorMessageAndTitleExist?: boolean, portal?: string): void {
    // if endpoint does not exist on the server and cannot be found
    if (err.status === 404) {
      errorModal.isShown = true;
      errorModal.title = 'Server Error';
      errorModal.bodyText = 'Endpoint cannot be found on the server.';
      return;
    }
    if (err.status === 400 || err.status === 409) {
      errorModal.isShown = true;
      if (!errorMessageAndTitleExist) {
        let title = 'Warning';
        if (err.error && err.error.message === 'The total of this order can not be increased because you have selected credit card as the payment method') {
          title = 'Total Allowed Was Exceeded';
        } else if (err.error && err.error.message === 'Cart is not valid') {
          title = 'Invalid Cart';
        } else if (err.error && err.error.message === 'User has no active cart to checkout') {
          title = 'Inactive Cart';
        }
        errorModal.title = title;
        errorModal.bodyText = typeof err.error === 'string' ? err.error : err.error.message;
      }
    } else {
      this.pageErrorService.errorCode = err.status;
      this.pageErrorService.errorMessage = err.error_description ? err.error_description : err.error.message;
      this.router.navigate(['page-error']);
    }
  }


  loadHtml(htmlFilePath): Observable<{}> {
    return this.http.get(htmlFilePath, { responseType: 'text' });
  }

  set handleNotificationSignals(notifMsg: INotificationMessage) {
    const role = sessionStorage.getItem('user_role');
    switch (notifMsg.notificationType) {
      case NotificationTypesEnum.priceDrop:
        if (['BUYER', 'SALES', 'SUPER'].includes(role)) {
          this.pubSubService.sharedSubject.next({ name: SharedSource.priceDropFromVendor, data: notifMsg });
        } else if (['ADMIN', 'SELLER', 'VENDORMGR'].includes(role)) {
          this.pubSubService.sharedSubject.next({ name: SharedSource.priceDropFromBuyer, data: notifMsg });
        }
        break;

      case NotificationTypesEnum.moreInventory:
        if (['BUYER', 'SALES', 'SUPER'].includes(role)) {
          this.pubSubService.sharedSubject.next({ name: SharedSource.moreInventoryFromVendor, data: notifMsg });
        } else if (['ADMIN', 'SELLER', 'VENDORMGR'].includes(role)) {
          this.pubSubService.sharedSubject.next({ name: SharedSource.moreInventoryFromBuyer, data: notifMsg });
        }
        break;

      case NotificationTypesEnum.waitlist:
        if (['BUYER', 'SALES', 'SUPER'].includes(role)) {
          this.pubSubService.sharedSubject.next({ name: SharedSource.waitlistFromVendor, data: notifMsg });
        } else if (['ADMIN', 'SELLER', 'VENDORMGR'].includes(role)) {
          this.pubSubService.sharedSubject.next({ name: SharedSource.waitlistFromBuyer, data: notifMsg });
        }
        break;
    }
  }

  set updateVersion(data) {
    this.pubSubService.sharedSubject.next({ name: SharedSource.newVersionAvailable, data: data });
  }


  set updateAuctionItem(item: IAuctionItem) {
    this.pubSubService.sharedSubject.next({ name: SharedSource.updateAuctionItem, data: item });
  }

  set updateAuctionNotifMsg(item: IAuctionItem) {
    this.pubSubService.sharedSubject.next({ name: SharedSource.updateAuctionNotifMsg, data: item });
  }

  set handleBuyerNotificationTabs(tab) {
    this.pubSubService.sharedSubject.next({ name: SharedSource.notificationTabBuyer, data: tab });
  }

  set handleVendorNotificationTabs(tab) {
    this.pubSubService.sharedSubject.next({ name: SharedSource.notificationTabVendor, data: tab });
  }

  set vendorNotificationNew(total) {
    this.pubSubService.sharedSubject.next({ name: SharedSource.vendorNotificationCount, data: total });
  }
  set setQtyOfNewInNotifCenterBuyer(qty) {
    this.pubSubService.sharedSubject.next({ name: SharedSource.qtyOfNewInNotifCenterBuyer, data: qty });
  }

  set refreshItems(val) {
    this.pubSubService.sharedSubject.next({ name: SharedSource.refreshItemsAfterAuth, data: val });
  }

  set setAuctionItems(data: IAuctionItem[]) {
    this.pubSubService.sharedSubject.next({ name: SharedSource.auctionItems, data: data });
  }

  triggerChangeDetection() {
    this.pubSubService.sharedSubject.next({ name: SharedSource.triggerChangeDetection, data: true });
  }

  closeAllDropdowns() {
    this.pubSubService.sharedSubject.next({ name: SharedSource.closeAllDropdowns, data: true });
  }

  itemRestrictedForCountry(item: IItem) {
    this.pubSubService.sharedSubject.next({ name: SharedSource.itemRestrictedForCountry, data: item });
  }

  set handleOffer(offerNotifMsgParam: string) {
    this.zone.run(() => {
      const offerNotifMsg: IOfferNotificationMessage = JSON.parse(offerNotifMsgParam, this.toCamelCase);
      const role = sessionStorage.getItem('user_role');

      switch (offerNotifMsg.notificationType) {
        case OfferNotificationTypesEnum.offerFeatureSwitched:
          this.pubSubService.sharedSubject.next({ name: SharedSource.handleOfferFeatureSwitched, data: offerNotifMsg.data as IOfferFeatureSwitchedMessage });

          break;

        case OfferNotificationTypesEnum.tokenCountUpdated:
          if (['BUYER', 'SALES', 'SUPER'].includes(role)) {
            this.pubSubService.sharedSubject.next({ name: SharedSource.tokenCountUpdated, data: offerNotifMsg.data });
          }
          break;

        case OfferNotificationTypesEnum.offerValidationRulesUpdated:
          if (['BUYER', 'SALES', 'SUPER'].includes(role)) {
            this.pubSubService.sharedSubject.next({ name: SharedSource.offerValidationRulesUpdated, data: offerNotifMsg.data });
          }
          break;

        case OfferNotificationTypesEnum.offerEvent:
          this.pubSubService.sharedSubject.next({ name: SharedSource.offerEvent, data: offerNotifMsg.data as IOfferEventMessage });
          break;

        case OfferNotificationTypesEnum.offerSeen:
          this.pubSubService.sharedSubject.next({ name: SharedSource.offerSeen, data: offerNotifMsg.data as IOfferSeenMessage });
          break;
      }
    });

  }


  /**
 * Show preCart
 */
  set showPreCart(val: boolean) {
    this._showPreCart = val;
    this.pubSubService.sharedSubject.next({ name: SharedSource.showPreCart, data: val });
  }

  get showPreCart(): boolean {
    return this._showPreCart;
  }

  /**
 * Navigation tree has values
 */
  set navigationReady(val: boolean) {
    this._navigationReady = val;
    this.pubSubService.sharedSubject.next({ name: SharedSource.navigationReady, data: val });
  }

  get navigationReady(): boolean {
    return this._navigationReady;
  }

  isBuyerPortal() {
    return this.isBuyerRole;
  }

  isVendorPortal() {
    return this.isVendorRole;
  }

  get isBuyerRole() {
    return ['BUYER', 'SALES', 'SUPER'].includes(sessionStorage.getItem('user_role'));
  }

  get isVendorRole() {
    return ['SELLER', 'ADMIN', 'VENDORMGR'].includes(sessionStorage.getItem('user_role'));
  }

  get isVendorLoginUrl() {
    return /^\/vendor-login$/.test(window.location.pathname);
  }

  get isVendorPortalUrl() {
    return /^\/vendor$/.test(window.location.pathname) ||
      /^\/vendor\//.test(window.location.pathname);
  }

  toCamelCase(key, value) {
    if (value && typeof value === 'object') {
      for (var k in value) {
        if (/^[A-Z]/.test(k) && Object.hasOwnProperty.call(value, k)) {
          value[k.charAt(0).toLowerCase() + k.substring(1)] = value[k];
          delete value[k];
        }
      }
    }
    return value;
  }

  countDecimals(value) {
    let text = value.toString()
    // verify if number has six or more decimal places
    if (text.indexOf('e-') > -1) {
      let [base, trail] = text.split('e-');
      let deg = parseInt(trail, 10);
      return deg;
    }
    // count decimals for number in representation like "0.123456"
    if (Math.floor(value) !== value) {
      return value.toString().split(".")[1].length || 0;
    }
    return 0;
  }

}
