export enum SharedSource {
  changeCustomer,
  changeCustomerVendor,
  vendorId,
  isPendingPo,
  itemsListChanged,
  itemsListFetched,
  noOrders,
  editOrderMode,
  catalogViewSwitch,
  vendorLoggedIn,
  orderSearch,
  dropdownDirective,
  profileData,
  cartFetch,
  mobileSize,
  mobileSortingPopUpOpen,
  showDetailedPopUpOpen,
  topCategory,
  breadCrumbsTitle,
  selectedItemChanged,
  navChanged,
  vendorComponentUpdated,
  itemsScrollPosition,
  LoggedInInternal,
  LoggedInGuest,
  itemsScrollLeftPosition,
  itemsScrollingStarted,
  isGradingGuide,
  JustSoldValues,
  IsJustSoldSelected,
  newPriceUpdated,
  IsSearch,
  topFiltersOutletChanged,

  priceDropFromBuyer,
  priceDropFromVendor,
  waitlistFromBuyer,
  waitlistFromVendor,
  moreInventoryFromBuyer,
  moreInventoryFromVendor,

  notificationTabBuyer,
  notificationTabVendor,
  notificationBuyerTotals,
  vendorNotificationCount,
  qtyOfNewInNotifCenterBuyer,

  updateAuctionItem,
  updateAuctionNotifMsg,

  showPreCart,

  // filter and breadcrumbs
  pastPurchaseSearch,
  manufacturerChangedInFilter,
  sortChangedInFilter,
  clearAllFilter,
  newVersionAvailable,
  selectedZeroLevelChanged,

  // navigationsData and manufacturersData
  navigationsDataChanged,
  manufacturersDataChanged,
  filterManufacturersDataChanged,
  outletNavigationsDataChanged,
  manufacturersBySavedFilterDataChanged,
  pageNavigationsDataChanged,

  forgotPasswordRequestFailed,
  navigationReady,
  refreshItemsAfterAuth,
  refreshNotificationItem,

  loaderStatus,
  loaderVisibility,
  loaderVendorVisibility,
  auctionItems,
  closeAllDropdowns,

  partnerIdReady,
  triggerChangeDetection,
  itemRestrictedForCountry,
  offerSummary,

  // Offers
  handleOfferFeatureSwitched,
  tokenCountUpdated,
  offerValidationRulesUpdated,
  offerEvent,
  offerSeen,
  offerItemChanged,
  refreshItemList,
  sessionSwitchedAndSummaryReady,
  offerFeatureSwitched,
  offerAccepted,
  offerRejected,
  handleNewOfferEventReceived,
  bpUnseenEventCountUpdated,
  updateOffersByStatusDropdown,
  handleOfferError,
  offerCanceled,
  previousOfferAccepted,
  userProfileLoaded,

  buyerOffersQty,

}
