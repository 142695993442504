import * as models from './model';
export interface IProfile {
    companyName: string;
    bpCode: string;
    country: string;
    contact: {
        firstName: string;
        lastName: string;
        email: string;
    };
    beneficiaryBank:{
        bankName: string;
        address: string;
        city: string;
        stateProvince: string;
        country: string;
        zipCode: string;
        routingNumber: string;
        ach: string;
        swiftBicCode: string;
        accountNumber: string;
        intermediaryBIC: string;
        beneficiaryName: string;
        beneficiaryAddress: string;
        beneficiaryCity: string;
        beneficiaryStateProvince: string;
        beneficiaryCountry: string;
        beneficiaryZipCode: string;
    };
    billingAddresses: models.IAddress[];
    shippingAddresses: models.IAddress[];
    discount: number;
    hasOpenOrders: false;
    terms: string;
    isCreditCardAllowed: false;
}
