import { Component, OnInit, Input } from '@angular/core';
import { TopService } from '../../../services/top.service';
import { NgFor, NgIf } from '@angular/common';

@Component({
    selector: 'app-items-preloader-mobile',
    templateUrl: './items-preloader-mobile.component.html',
    styleUrls: ['../items-preloader.component.scss'],
    standalone: true,
    imports: [NgFor, NgIf]
})
export class ItemsPreloaderMobileComponent implements OnInit {

  @Input() isFromPastPurchases: boolean = false;
  @Input() isFromJustSold: boolean = false;
  @Input() isFromNotificationCenter: boolean = false;
  preloaderItemList = Array(4);

  constructor(
  	public topService: TopService,
  	) { }

  ngOnInit(): void {
  }

}
